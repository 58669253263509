import React from 'react';
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import ergonaIcon from "src/images/ergona.png";
import rtwIcon from "src/images/RTW.png";
import wwIcon from "src/images/wwapp.png";
import allegroIcon from "src/images/allegro.jpeg";
import Image from "@amzn/meridian/image";
import Text from "@amzn/meridian/text";
import useBreakpoint from "@amzn/meridian/use-breakpoint";
import Cookies from 'universal-cookie';

export const detectMobileOnResize = (breakpointState: String) => {
    return breakpointState === "smaller";
};
export const setCookie = () => {
    const cookies = new Cookies();
    cookies.set("previousUrl", window.location.href, {path:"/"});
}
const App = () => {
    const breakpointState = useBreakpoint("600px");
    const isMobile = detectMobileOnResize(breakpointState);
    return <>
            <Row alignmentHorizontal={"center"} spacingInset="600">
                <Column width={"100%"} alignmentHorizontal={"center"}>
                    <Text type={"h600"}>Welcome to AppStack Poc</Text>
                </Column>
            </Row>
        <Column alignmentHorizontal={"center"}>
                <Row
                    alignmentHorizontal={"center"}
                    width={"80%"}
                    widths={["50%", "50%"]}
                    spacingInset="400 none none none"
                >
                    <div>
                        <Column alignmentHorizontal={"center"}>
                            <a onClick={setCookie} href={"https://tankaite.na.ergona.whs.amazon.dev/"}
                               style={{
                                   display: 'block',
                                   width: '400px',
                                   textAlign: "center"
                               }}
                            >
                                <Image src={ergonaIcon} size='contain'/>
                                <Text type={"h600"}>Ergona</Text></a>
                        </Column>
                    </div>
                    <div>
                        <Column alignmentHorizontal={"center"}>
                            <a onClick={setCookie} href={"https://beta.na.rtw.whs.amazon.dev/"}
                               style={{
                                   display: 'block',
                                   width: '400px',
                                   textAlign: "center"
                               }}
                            >
                                <Image src={rtwIcon} size='contain'/>
                                <Text type={"h600"}>Return To Work</Text></a>
                        </Column>
                    </div>
                </Row>
                <Row
                    alignmentHorizontal={"center"}
                    width={"80%"}
                    widths={["50%", "50%"]}
                    spacingInset="400 none none none"
                >
                    <div>
                        <Column alignmentHorizontal={"center"}>
                            <a onClick={setCookie} href={"https://tankaite-test.d3pf98ket9ec8c.amplifyapp.com/"}
                               style={{
                                   display: 'block',
                                   width: '400px',
                                   textAlign: "center"
                               }}
                            >
                                <Image src={wwIcon}  size='contain'/>
                                <Text type={"h600"}>Working Well</Text> </a>
                        </Column>
                    </div>
                    <div>
                        <Column alignmentHorizontal={"center"}>
                            <a onClick={setCookie}href={"https://tankaite-test.d3pf98ket9ec8c.amplifyapp.com/headphones"}
                               style={{
                                   display: 'block',
                                   width: '400px',
                                   textAlign: "center"
                               }}
                            >
                                <Image src={allegroIcon} size='contain'/>
                                <Text type={"h600"}>Allegro</Text></a>
                        </Column>
                    </div>
                </Row>
            </Column>
        </>;
}
export default App;
