import Auth, {CognitoUser} from "@aws-amplify/auth";


const ErgonaAuth = {
    stage: "beta",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:43acbe7a-8a89-49e7-9749-4b83e977ce62",
    aws_user_pools_id: "us-east-1_OjgbYz3oa",
    aws_user_pools_web_client_id: "71rin72epi1am7tc1q5g3hb4kk",
    Auth: {
        region: "us-east-1",
        identityPoolId: "us-east-1:43acbe7a-8a89-49e7-9749-4b83e977ce62",
        userPoolId: "us-east-1_OjgbYz3oa",
        userPoolWebClientId: "71rin72epi1am7tc1q5g3hb4kk"
    },
    oauth: {
        domain: "ergona-beta-na.auth.us-east-1.amazoncognito.com",
        redirectSignIn: "https://beta.na.kai-personal.whs.amazon.dev",
        redirectSignOut: "https://beta.na.kai-personal.whs.amazon.dev",
        // redirectSignIn: "http://localhost:3000",
        // redirectSignOut: "http://localhost:3000",
        scope: ["openid", "profile", "email"],
        responseType: "code",
    },
};

const wwapp = {
    stage: "alpha",
    aws_cognito_region: "us-west-2",
    aws_cognito_identity_pool_id: "us-west-2:83e3a74b-354f-469a-8eb8-27eb501ebd33",
    aws_user_pools_id: "us-west-2_YpKqMGHgE",
    aws_user_pools_web_client_id: "4qem92tisk93p4su3ldc1uln9l",
    Auth: {
        region: "us-west-2",
        identityPoolId: "us-west-2:83e3a74b-354f-469a-8eb8-27eb501ebd33",
        userPoolId: "us-west-2_YpKqMGHgE",
        userPoolWebClientId: "4qem92tisk93p4su3ldc1uln9l"
    },
    oauth: {
        domain: "workingwell-oauth-dev.auth.us-west-2.amazoncognito.com",
        redirectSignIn: "https://beta.na.kai-personal.whs.amazon.dev",
        redirectSignOut: "https://beta.na.kai-personal.whs.amazon.dev",
        scope: ["openid", "profile", "email", "aws.cognito.signin.user.admin"],
        responseType: "code",
    },
}

const getErgonaUser = async (): Promise<CognitoUser | null> => {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (e) {
        console.error("getCurrentUser err: ", e);
        return null;
    }
};

const ergonaSignIn = () => {
    Auth.federatedSignIn({customProvider: "FederateOIDC"}).then(res => {
        console.info("new session created / user signed in");
    });
};



const ergonaLogin = async () => {
    const ergonaUser = await getErgonaUser();
    if (!ergonaUser) {
        console.info("No session found for ergona, attempting to start a new one...");
        await ergonaSignIn();
        console.log("ergona user signed in");
    }
}

const getwwUser = async (): Promise<CognitoUser | null> => {
    try {
        Auth.configure(wwapp);
        return await Auth.currentAuthenticatedUser();
    } catch (e) {
        console.error("getCurrentUser ww err: ", e);
        return null;
    }
};

const wwSignIn = () => {
    Auth.configure(wwapp);
    Auth.federatedSignIn({customProvider: "FederateOIDC"}).then(res => {
        console.info("ww new session created / ww user signed in");
    });
};



const wwLogin = async () => {
    const wwUser = await getwwUser();
    if (!wwUser) {
        console.info("No ww session found, attempting to start a new one...");
        await wwSignIn();
        console.log("ww user signed in");
    }
}




export async function Init(): Promise<void> {
    Auth.configure(ErgonaAuth);
    ergonaLogin();
}
